import React from "react";
import {
  StyledBanner,
  StyledBannerImage,
  StyledBannerContent,
  StyledBannerText,
  StyledBannerCta,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import { StyledBannerAuthor } from "./style";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default ({
  image, text, author, cta, allImages, 
}) => {
  const imageData = allImages.find(e => e.path === image);

  return (
    <StyledGridRow isFull>
      <StyledBanner>
        <StyledBannerImage >
          {imageData && <GatsbyImage image={getImage(imageData)} alt={imageData.alt} />}
        </StyledBannerImage>
        <StyledBannerContent>
          <StyledBannerText dangerouslySetInnerHTML={{ __html: text }} />
          <StyledBannerAuthor dangerouslySetInnerHTML={{ __html: author }} />
          {cta && (
            <StyledBannerCta>
              <Button as="div" theme="transparent" to={cta.url}>
                {cta.label}
                <Icon icon={icons.arrowRight} color={Colors.yellow} />
              </Button>
            </StyledBannerCta>
          )}
        </StyledBannerContent>
      </StyledBanner>
    </StyledGridRow>
  );
};

/*<StyledBannerImage bg={image} />*/
